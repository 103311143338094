import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { CSpinner } from '@coreui/react';

import '../i18n';
import 'global/scss/style.scss';

const loading = (
  <div className="pt-5 mt-5 text-center">
    <CSpinner grow color="primary" />
  </div>
);
// Containers
const TheLayout = React.lazy(() => import('../containers/main/TheLayout'));

// Pages
const Login = React.lazy(() => import('../pages/Login'));
const Register = React.lazy(() => import('../pages/register/Register'));
const ForgotPassword = React.lazy(() => import('../pages/ForgotPassword'));
const ResetPassword = React.lazy(() => import('../pages/ResetPassword'));
const Success = React.lazy(() => import('../pages/register/Success'));
const ActivePassword = React.lazy(() => import('../pages/ActivePassword'));
const ConfirmEmail = React.lazy(() => import('../pages/ConfirmEmail'));

const App = () => {
  return (
    <Router>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route path="/forgot-password" name="Przypomnienie hasła">
            <ForgotPassword />
          </Route>
          <Route path="/reset-password" name="Resetowanie hasła">
            <ResetPassword />
          </Route>
          <Route path="/register" name="Rejestracja">
            <Register />
          </Route>
          <Route path="/login" name="Logowanie">
            <Login />
          </Route>
          <Route path="/activate" name="Ustaw hasło">
            <ActivePassword />
          </Route>
          <Route path="/remind-password" name="Zmień hasło">
            <ResetPassword />
          </Route>
          <Route path="/success" name="Płatność - sukces">
            <Success />
          </Route>
          <Route path="/change-email" name="Płatność - sukces">
            <ConfirmEmail />
          </Route>
          <Route path="/" name="Home" render={(props) => <TheLayout {...props} />} />
        </Switch>
      </React.Suspense>
    </Router>
  );
};

export default App;
