import { combineReducers } from 'redux';

import roles from './roles/roles';
import general from './general/general';
import errors from './errors/errors';
import newUser from './newUser/newUser';

export default combineReducers({
  roles,
  general,
  errors,
  newUser
});
