import {
  SET_ROLE,
  SET_EMPLOYMENT_FORM,
  SET_PERSONAL_DATA,
  SET_ADDRESS,
  SET_POSITION,
  RESET_NEW_USER
} from '../../actions/newUser/types';

const initialState = {
  role: '',
  employmentForm: '',
  personalData: '',
  address: '',
  positions: ''
};

export default function (state = initialState, action) {
  const { type, role, employmentForm, personalData, address, positions } = action;

  switch (type) {
    case SET_ROLE:
      return { ...state, role: role };
    case SET_EMPLOYMENT_FORM:
      return { ...state, employmentForm: employmentForm };
    case SET_PERSONAL_DATA:
      return { ...state, personalData: personalData };
    case SET_ADDRESS:
      return { ...state, address: address };
    case SET_POSITION:
      return { ...state, positions: positions };
    case RESET_NEW_USER:
      return initialState;
    default:
      return state;
  }
}
