import { SET_PERMISSIONS, SET_ROLES } from '../../actions/roles/types';

const initialState = { userPermissions: [], roles: '' };

export default function (state = initialState, action) {
  const { type, userPermissions, roles } = action;

  switch (type) {
    case SET_PERMISSIONS:
      return { ...state, userPermissions: userPermissions };
    case SET_ROLES:
      return { ...state, roles: roles };

    default:
      return state;
  }
}
