import {
  SET_SIDEBARSHOW,
  SET_DARKMODE,
  SET_ONBOARDING,
  SET_MANAGER,
  SET_LOGGED_FOR_PAY,
  RESET,
  SET_PROFILE_IMAGE
} from '../../actions/general/types';
SET_LOGGED_FOR_PAY;
const initialState = {
  sidebarShow: true,
  darkMode: localStorage.getItem('simpliance.darkMode') === 'true',
  onboardingPassed: false,
  onboardingHistory: undefined,
  isManager: false,
  loggedForPay: false,
  profileImage: ''
};

export default function (state = initialState, action) {
  const {
    type,
    sidebarShow,
    darkMode,
    onboardingPassed,
    onboardingHistory,
    isManager,
    loggedForPay,
    profileImage
  } = action;

  switch (type) {
    case SET_SIDEBARSHOW:
      return { ...state, sidebarShow: sidebarShow };
    case SET_DARKMODE:
      return { ...state, darkMode: darkMode };
    case SET_ONBOARDING:
      return { ...state, onboardingPassed: onboardingPassed, onboardingHistory: onboardingHistory };
    case SET_MANAGER:
      return { ...state, isManager: isManager };
    case SET_LOGGED_FOR_PAY:
      return { ...state, loggedForPay: loggedForPay };
    case SET_PROFILE_IMAGE:
      return { ...state, profileImage: profileImage };
    case RESET:
      return initialState;

    default:
      return state;
  }
}
